import { Navigate } from "react-router-dom";

const RoleRoute = ({ children, role, roles = [] }) => {

    if (roles.length) {
        if (!role) {
            return <Navigate to="/login" />
        } else {
            return roles.includes(role) ? children : <Navigate to="/unauthorized" />;
        }
    } else {
        return children;
    }
}
export default RoleRoute;

