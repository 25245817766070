import React, { useContext, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Popup } from 'react-map-gl';
import MapView from './../index';
import { DataContext } from '../../../context/DataContext';

const VesselPopup = ({ marker, markerDetails, setMarkerDetails }) => {
    const { area: areas, lat, lon, id, icon, stream, payload } = marker;
    return (markerDetails && markerDetails.id === id) &&
        <Popup className='geo-popup w-75' id={id} key={id} latitude={lat} onClose={() => setMarkerDetails(null)} longitude={lon} closeOnClick={false}  >
            <Card>
                <Card.Body>
                    <Card.Text>
                        <Card.Text>
                            <ul>
                                {Object.keys(payload).map((key, index) => {
                                    return (
                                        <li key={`${key}-${index}`}><strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong> : {payload[key]}</li>
                                    )
                                })}
                            </ul>
                        </Card.Text>
                    </Card.Text>
                </Card.Body>
            </Card>

        </Popup>
}

export default VesselPopup