import { useEffect, useContext, useState } from 'react';
import { Modal, Nav } from 'react-bootstrap';
import { XSquare } from "react-bootstrap-icons";
import { GeneralContext } from '../../context/GeneralContext';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactJsonViewCompare from 'react-json-view-compare';

import './theme.css';

const Files = ({ className = '', filters = true, pagination = true, items, stream }) => {

    const { axios } = useContext(GeneralContext);

    const [fileContentDetails, setFileContentDetails] = useState({ extension: '', data: '' });
    const [error, setError] = useState(false);

    const columns = [
        {
            dataField: 'data.json.name',
            text: 'Name',
            sort: true,
            filter: filters ? textFilter() : null
        },
        {
            dataField: 'data.json.mimetype',
            text: 'Mimetype',
            sort: true,
            filter: filters ? textFilter() : null,
        },
        {
            dataField: 'data',
            text: 'Files',
            sort: true,
            formatter: (data, index) => {
                const { json: { id, extension, mimetype, hash } } = data;
                return <Nav.Link key={`${id}-files`} onClick={(e) => handleFileContent(stream, extension, mimetype, `${hash}.${extension}`)} eventKey="link-files">{hash}</Nav.Link>
            },
        },
    ];

    const handleFileContent = async (stream, extension, mimetype, hash) => {
        try {
            const { data: { error, data: content } } = await axios.get(`/${stream}-document-content/transactions-by-key/${hash}`);
            if (!error) {
                setFileContentDetails({ extension, data: extension === 'json' ? JSON.parse(Buffer.from(content, "base64").toString()) : `data:${mimetype};base64,${content}` });
            }
        } catch (err) {
            setFileContentDetails({ extension: '', data: '' });
            setError(true);
        }
    }

    const hadleFileRender = ({ extension, data, filename }) => {
        switch (true) {
            case ['pdf'].includes(extension):
                return <embed width="100%" src={data}/>
            case ['xlsx', 'xls', 'csv'].includes(extension):
                return <div><span className={'text-danger'}>Unable to render file in browser, please download it below to view it.</span> <br/>
                <a
                    className="btn btn-primary mt-10"
                    download={filename}
                    href={`${data}`}
                >{`Download ${filename}`}</a></div>;
            case ['json'].includes(extension):
                return <ReactJsonViewCompare oldData={data} newData={data}/>
            case ['png', 'jpeg', 'jpg', 'gif', 'webp'].includes(extension):
                return <img alt="Render Images" width="100%" src={data}/>
            default:
                return <></>
        }
    }

    useEffect(() => {
    }, [fileContentDetails]);

    return (
        <div>
            <Modal
                className={`${className} max-w-full rounded-md p-6`}
                size="lg"
                show={!!fileContentDetails.data}
                onHide={() => setFileContentDetails({ extension: '', data: '' })}
            >
                <Modal.Header
                    className="flex items-center justify-between"
                >
                    <Modal.Title><h6 className='mt-3'>File</h6></Modal.Title>
                    <XSquare className='m-3' onClick={() => setFileContentDetails({ extension: '', data: '' })} size={17} />

                </Modal.Header>
                <Modal.Body className="h-100">
                    {hadleFileRender(fileContentDetails)}
                    {error && <div className="text-danger">Unable to load file</div>}
                </Modal.Body>
                <Modal.Footer>
                    <div onClick={() => setFileContentDetails({ extension: '', data: '' })} className="btn btn-info">Close</div>
                </Modal.Footer>
            </Modal>
            <BootstrapTable
                fluid
                bootstrap4
                keyField='txId'
                columns={columns}
                data={items}
                filter={filterFactory()}
                filterPosition="top"
                pagination={pagination ? paginationFactory() : null}
            />
        </div>
    )
}

export default Files;