import { useEffect, useRef } from 'react';

export function useScrollableSlider() {
    const sliderEl = useRef(null);
    const sliderPrevBtn = useRef(null);
    const sliderNextBtn = useRef(null);

    function scrollToTheRight() {
        let offsetWidth = sliderEl.current.offsetWidth;
        sliderEl.current.scrollLeft += offsetWidth;
    }

    function scrollToTheLeft() {
        let offsetWidth = sliderEl.current.offsetWidth;
        sliderEl.current.scrollLeft -= offsetWidth;
    }

    useEffect(() => {
        const filterBarEl = sliderEl.current;
        const prevBtn = sliderPrevBtn.current;
        const nextBtn = sliderNextBtn.current;
        const formPageHeaderEl = filterBarEl.classList.contains(
            'formPageHeaderSliderElJS'
        );
        initNextPrevBtnVisibility();

        // @ts-ignore
        function initNextPrevBtnVisibility() {
            let offsetWidth = filterBarEl.offsetWidth;
            let scrollWidth = filterBarEl.scrollWidth;
            // show next btn when scrollWidth is gather than offsetWidth
            if (scrollWidth > offsetWidth) {
                nextBtn?.classList.remove('opacity-0', 'invisible');
                if (formPageHeaderEl) {
                    filterBarEl?.classList.add('!-mb-[43px]');
                }
            } else {
                if (formPageHeaderEl) {
                    filterBarEl?.classList.remove('!-mb-[43px]');
                }
            }
        }

        function visibleNextAndPrevBtnOnScroll() {
            let newScrollLeft = filterBarEl?.scrollLeft,
                offsetWidth = filterBarEl?.offsetWidth,
                scrollWidth = filterBarEl?.scrollWidth;
            // reach to the right end
            if (scrollWidth - newScrollLeft == offsetWidth) {
                prevBtn?.classList.remove('opacity-0', 'invisible');
            } else {
                nextBtn?.classList.remove('opacity-0', 'invisible');
            }
            // reach to the left end
            if (newScrollLeft === 0) {
                nextBtn?.classList.remove('opacity-0', 'invisible');
            } else {
                prevBtn?.classList.remove('opacity-0', 'invisible');
            }
        }

        window.addEventListener('resize', initNextPrevBtnVisibility);
        filterBarEl.addEventListener('scroll', visibleNextAndPrevBtnOnScroll);
        // clear event
        return () => {
            window.removeEventListener('resize', initNextPrevBtnVisibility);
            filterBarEl.removeEventListener('scroll', visibleNextAndPrevBtnOnScroll);
        };
    }, []);

    return {
        sliderEl,
        sliderPrevBtn,
        sliderNextBtn,
        scrollToTheRight,
        scrollToTheLeft,
    };
}
