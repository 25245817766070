import WidgetCard from './../Card/widget-card';

import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
    Area,
} from 'recharts';

const Forecast = ({ labels, description, title, data, className, key1, key2 }) => {

    return (

        <WidgetCard
            title={title}
            titleClassName="font-normal sm:text-sm text-gray-500 mb-2.5 font-inter text-left"
            className={className}
            description={
                <div className="me-2 text-left">
                    {description}
                </div>
            }
            action={
                <div className="flex items-center justify-between gap-5">
                    <Legend labels={labels} className="@2xl:flex @3xl:hidden @5xl:flex" />
                </div>
            }
        >
            <ResponsiveContainer
                className={className}
                width="100%"
                height="100%"
            >
                <AreaChart
                    data={data}
                    margin={{
                        left: -10,
                    }}
                    className="[&_.recharts-cartesian-axis-tick-value]:fill-gray-500 rtl:[&_.recharts-cartesian-axis.yAxis]:-translate-x-12 [&_.recharts-cartesian-grid-vertical]:opacity-0"
                >
                    <defs>
                        <linearGradient id={key1} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#6B46FF" stopOpacity={0.1} />
                            <stop offset="95%" stopColor="#6B46FF" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id={key2} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#00D1FF" stopOpacity={0.1} />
                            <stop offset="95%" stopColor="#00D1FF" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="8 10" strokeOpacity={0.435} />
                    <XAxis dataKey="key" axisLine={false} tickLine={false} />
                    <YAxis
                        tickLine={false}
                        axisLine={false}
                        tick={({ payload, ...rest }) => {
                            const pl = {
                                ...payload,
                                value: payload.value,
                            };
                            return (
                                <></>
                            );
                        }}
                    />
                    <Tooltip />
                    <Area
                        type="monotone"
                        dataKey={key1}
                        stroke="#6B46FF"
                        strokeWidth={2}
                        fillOpacity={0}
                    />
                    <Area
                        type="monotone"
                        dataKey={key2}
                        stroke="#00D1FF"
                        strokeWidth={2}
                        fillOpacity={0}
                        offset={10}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </WidgetCard>
    );
}

const Legend = ({ labels, className }) => {
    return (
        <div className={`flex flex-wrap items-start gap-3 lg:gap-4 ${className}`}>
            {
                labels.map(({ name, color }) => {
                    return (
                        <span key={name} className="flex items-center gap-1.5">
                            <span className={`h-2.5 w-2.5 rounded-full bg-[${color}]`} />
                            <span>{name}</span>
                        </span>
                    )
                })
            }
        </div>
    );
}

export default Forecast
