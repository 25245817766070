import { forwardRef } from 'react';

const widgetCardClasses = {
  base: 'border border-muted bg-gray-0 dark:bg-gray-50 lg:p-5',
  rounded: {
    sm: 'rounded-sm',
    DEFAULT: 'rounded-lg',
    lg: 'rounded-xl',
    xl: 'rounded-2xl',
  },
};

const WidgetCard = (
  {
    title,
    action,
    description,
    rounded = 'DEFAULT',
    className,
    headerClassName,
    actionClassName,
    titleClassName,
    descriptionClassName,
    children,
  },
  ref
) => {
  return (
    <div
      className={`${widgetCardClasses.base} ${widgetCardClasses.rounded[rounded]} ${className}`}
      ref={ref}
    >
      <div
        className={`${action} flex items-start justify-between ${headerClassName}`}
      >
        <div>
          <div
            as="h3"
            className={`text-base font-semibold sm:text-lg ${titleClassName}`}
          >
            {title}
          </div>
          {description && (
            <div className={descriptionClassName}>{description}</div>
          )}
        </div>
        {action && <div
          className={`ps-2 ${actionClassName}`}
        >{action}</div>}
      </div>
      {children}
    </div>
  );
}

export default forwardRef(WidgetCard);
WidgetCard.displayName = 'WidgetCard';
