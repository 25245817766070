import { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import ReactJsonViewCompare from 'react-json-view-compare';
import Timeline from './../Timeline/index';
import History from './../History/index';
import MapView from './../Map/index';
import Files from './../Files/index';
import { DataContext } from '../../context/DataContext';
import { XSquare } from "react-bootstrap-icons";
import './theme.css';

const CustomModal = ({ modalData, stages, show, handleClose, stream, id }) => {

    const { mapDefaultLatitude, mapDefaultLongitude, mapDefaultStyle } = useContext(DataContext);

    const [title, setTitle] = useState('');
    const [content, setContent] = useState(<></>);

    const handleContent = (details) => {
        if(!details) return;
        const { type, data } = details;
        let formattedTitle = stream ? `${stream.charAt(0).toUpperCase() + stream.slice(1)} - ${id}` : "";

        switch (type) {
            case 'timeline':
                setTitle(`Timeline for ${formattedTitle}`);
                setContent(<Timeline stages={stages} items={data} />);
                break;
            case 'history':
                const { title } = modalData;
                formattedTitle = title || `History for ${formattedTitle}`;
                setTitle(formattedTitle);
                setContent(<History items={data} />);
                break;
            case 'transaction':
                setTitle(`Transactions for ${formattedTitle}`);
                setContent(<ReactJsonViewCompare oldData={data} newData={data} />);
                break;
            case 'file':
                setTitle(`${stream} File for ${formattedTitle}`);
                setContent(<Files stream={stream} items={data} />);
                break;
            case 'maptrace':
                const coordinates = data.map(({ data: { json: { lat, lon } } }) => ([lat, lon]));
                const traceViewport = data.map(({ data: { json: { lat, lon } } }) => ({ latitude: lat, longitude: lon })).pop();
                setTitle(`Trace for ${formattedTitle}`);
                setContent(coordinates.length ? <MapView
                    zoom={3}
                    route={{
                        type: "Feature",
                        properties: {},
                        geometry: {
                            type: "LineString",
                            coordinates,
                        }
                    }}
                    setViewport={() => { }}
                    mapType={mapDefaultStyle}
                    viewport={traceViewport}
                /> : <></>);
                break;
            case 'location':
                const markers = data.map(({ data: { json: { lat, lon } } }) => ({ lat, lon }));
                const marker = markers.length ? markers.pop() : false;
                const locationViewport = data.map(({ data: { json: { lat, lon } } }) => ({ latitude: lat, longitude: lon })).pop();
                setTitle(`Trace for ${formattedTitle}`);
                setContent(marker ? <MapView
                    mapElements={{ markers: [{ ...marker, type: 'marker' }], areas: null }}
                    viewport={{ ...locationViewport }}
                    zoom={3}
                    setViewport={() => { }}
                    mapType={mapDefaultStyle}
                /> : <></>);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        handleContent(modalData);
    }, [modalData]);

    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            className='max-w-full rounded-md p-6'
        >
            <Modal.Header
                className="flex items-center justify-between"
            >
                <Modal.Title><h6 className='mt-3'>{title}</h6></Modal.Title>
                <XSquare className='m-3' onClick={handleClose} size={17} />
            </Modal.Header>
            <Modal.Body className='h-100'>{content}</Modal.Body>
            <Modal.Footer>
                <div onClick={handleClose} className="btn btn-info">Close</div>
            </Modal.Footer>
        </Modal>
    );

}

export default CustomModal;