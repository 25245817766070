import WidgetCard from './../Card/widget-card';

import {
    Area,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ComposedChart,
    ResponsiveContainer,
} from 'recharts';

const DeviceSessions = ({ description, title, data, className }) => {

    return (
        <WidgetCard
            title={title}
            titleClassName="font-normal sm:text-sm text-gray-500 mb-2.5 font-inter text-left"
            className={className}
            description={
                <div as="h2" className="me-2 text-left">
                  {description}
                </div>
            }
        >
            <ResponsiveContainer className={className} width="100%" height="100%">
                <ComposedChart
                    data={data}
                    margin={{
                        left: -28,
                    }}
                    barSize={14}
                    className="[&_.recharts-cartesian-axis-tick-value]:fill-gray-500 [&_.recharts-cartesian-axis.yAxis]:-translate-y-3 rtl:[&_.recharts-cartesian-axis.yAxis]:-translate-x-12"
                >
                    <defs>
                        <linearGradient
                            id="deviceSessionsMobile"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="5%"
                                stopColor="#F0F1FF"
                                className=" [stop-opacity:0.25] dark:[stop-opacity:0.2]"
                            />
                            <stop offset="95%" stopColor="#422F8A" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                            id="deviceSessionsDesktop"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="5%"
                                stopColor="#F0F1FF"
                                className="[stop-opacity:0.25] dark:[stop-opacity:0.2]"
                            />
                            <stop offset="95%" stopColor={"#b5adec"} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="day" axisLine={false} tickLine={false} />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tick={<></>}
                    />
                    <Tooltip />
                    <Area
                        type="monotone"
                        dataKey="emitted"
                        title="Emitted"
                        stroke={"#69B2F8"}
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#deviceSessionsDesktop)"
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </WidgetCard>
    );
}

export default DeviceSessions
