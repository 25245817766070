import { useState, useContext, useEffect } from "react";
import { GeneralContext } from './../../context/GeneralContext';
import { DataContext } from './../../context/DataContext';
import { useNavigate } from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();

    const { axios } = useContext(GeneralContext);
    const { handleLogin } = useContext(DataContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorLogin, setErrorLogin] = useState(false);

    const handleUsername = (e) => {
        setUsername(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await getUser();
    }

    const getUser = async () => {
        if(username === '' || password === '') {
            return setErrorLogin(true);
        }
        await axios.post("/user/login", { data: { username, password } }).catch((err) => {
            setErrorLogin(true);
            return Promise.reject(err);
        }).then(async (res) => {
            await handleLogin(res.data.data);
            await navigate("/");
        });
    };

    useEffect(() => {
    }, [errorLogin])

    return (
        <div className="main-content-container container-fluid p-4">
            <div className="row">
                <div className="col-lg-4 col-md-4"></div>
                <div className="col-lg-4 col-md-4">
                    <div className="wrapper fadeInDown">
                        <div id="formContent">
                            <form onSubmit={(e) => handleSubmit(e)} className="flex flex-grow flex-col @container [&_label]:font-medium">
                                <div className="form-group">
                                    <label>Username</label>
                                    <input
                                        onChange={(e) => handleUsername(e)}
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        aria-describedby="username"
                                        placeholder="Enter username"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        onChange={(e) => handlePassword(e)}
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Password"
                                    />
                                </div>
                                {errorLogin && <small id="emailHelp" className="form-text text-danger mb-3">Unable to authenticate, please double check your login details and try again.</small>}
                                <button type="submit" className="btn btn-info">Login</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4"></div>
            </div>
        </div >
    );
};

export default Login;