import MetricCard from './metric-card';
import { RoundedTopBarFill } from './rounded-topbar';
import { BarChart, Bar, ResponsiveContainer } from 'recharts';

const StatCards = ({ className, data}) => {

  return data.map((stat) => (
    <MetricCard
      key={stat.title + stat.id}
      title={stat.title}
      metric={stat.metric}
      rounded="lg"
      metricClassName="text-2xl mt-1"
      info={
        <div className="text-sm text-gray-500">
          {stat.info}
        </div>
      }
      chart={
        <>
        {stat.percentage ? <div
              if={stat.percentage}
            style={{ color: stat.fill }}
            className="mb-3 text-sm font-medium"
          >
            {stat.percentage}
          </div> : null}
          <div className="h-12 w-20 @[16.25rem]:h-16 @[16.25rem]:w-24 @xs:h-20 @xs:w-28">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart barSize={6} barGap={5} data={stat.chart}>
                <Bar
                  dataKey="sale"
                  fill={stat.fill}
                  shape={<RoundedTopBarFill cornerRadius={2} />}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      }
      chartClassName="flex flex-col w-auto h-auto text-center"
      className="@container @7xl:text-[15px] [&>div]:items-end"
    />
  ))
}

export default StatCards
