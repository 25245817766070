import React from 'react';
import { Marker } from 'react-map-gl';

const MapMarker = ({ marker, markerDetails, setMarkerDetails }) => {

    const { lat, lon, id, className } = marker;
    return (
        <Marker
            onClick={async () => {
                setMarkerDetails(markerDetails === null || markerDetails.id !== id ? marker : null);
            }}
            key={`marker-${id}`}
            latitude={lat}
            longitude={lon}
            anchor="top"
            className="custom-map-marker"
        >
            <div className={`marker ${className}`} />
        </Marker>
    )
}

export default MapMarker