import { useEffect, useState, useContext, useRef } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { DataContext } from '../../context/DataContext';
import MapView from './../../components/Map/index';
import { useLocation } from 'react-router-dom';
import { formatAreaCollection } from './../../utils';

import MapMarker from './../../components/Map/elements/marker'

import './theme.css';

const Well = () => {

    const { state } = useLocation();

    const { axios } = useContext(GeneralContext);
    const { mapDefaultLatitude, mapDefaultLongitude, mapDefaultStyle } = useContext(DataContext);

    const [mapElements, setMapElements] = useState({ markers: [], areas: null });
    const [viewport, setViewport] = useState({ latitude: mapDefaultLatitude, longitude: mapDefaultLongitude, zoom: 7 });
    const [markerDetails, setMarkerDetails] = useState(null);
    const [wellId, setWellId] = useState(state ? state.wellId : '');

    const getOilFieldsLocations = async () => {
        const { error, data: { data } } = await axios.get(`/location/transactions-by-key/${wellId || "well"}?page=1&items=1000`);
        const markers = data.map(({ data: { json: { lat, lon, id } } }) => ({ lat, lon, id, type: 'marker', icon: "barrel", className: "barrel", stream: "barrel" }));
        setMapElements({ markers, areas: { "type": "FeatureCollection", features: formatAreaCollection(markers) } })
    }

    useEffect(() => {
        const fetchOilFieldsLocationsData = async () => {
            await getOilFieldsLocations();
        }
        fetchOilFieldsLocationsData().catch(console.error);
    }, [])


    useEffect(() => {
    }, [mapElements])

    const MarkerList = () => {
        return mapElements.markers ?
            mapElements.markers.map((marker, index) => {
                return (
                    <div key={`markers-list-${index}`}>
                        <MapMarker marker={marker} setViewport={setViewport} markerDetails={markerDetails} setMarkerDetails={setMarkerDetails} />
                    </div>
                );
            }) : <></>;
    }

    return (
        <>
            <MapView
                viewport={viewport}
                setViewport={setViewport}
                mapElements={mapElements}
                mapType={mapDefaultStyle}
            >
                <MarkerList />
            </MapView>
            <div className="pabr text-center">
                <div className='text-center mt-4'>
                </div>
            </div>
        </>
    )

}

export default Well;