
const Unauthorized = () => {

    return (
        <div className="no-data p-4 m-4">
            <div className="alert alert-danger text-center">
                Unauthorized
            </div>
        </div>
    );
};

export default Unauthorized;