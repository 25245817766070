import { useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use/lib';

const useIsMounted = () => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => setMounted(true), []);
    return mounted;
}

const StickyHeader = ({
    offset = 2,
    className,
    children,
}) => {
    const isMounted = useIsMounted();
    const windowScroll = useWindowScroll();
    return (
        <header
            className={`sticky top-0 z-[9999] flex items-center bg-gray-0/80 p-3 py-2 px-4 backdrop-blur-xl md:px-5 lg:px-6 dark:bg-gray-50/50 ${((isMounted && windowScroll.y)) > offset ? 'card-shadow' : ''} ${className}`}
        >
            {children}
        </header>
    );
}

export default StickyHeader;
