import { createContext } from "react";
import axios from 'axios';
import Cookies from 'js-cookie';

const {
    REACT_APP_REST_HOST,
    REACT_APP_REST_PORT,
    REACT_APP_MAPS_KEY,
    REACT_APP_REST_PROTOCOL
} = process.env

export const env = {
    maps: REACT_APP_MAPS_KEY
}

axios.defaults.baseURL = `${REACT_APP_REST_PROTOCOL || "http"}://${REACT_APP_REST_HOST || "localhost"}:${REACT_APP_REST_PORT || 5000}`;
axios.interceptors.request.use(function (config) {
    config.headers.Authorization = Cookies.get("access_token") ? `Bearer ${Cookies.get("access_token")}` : null;
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default axios;

export const GeneralContext = createContext();