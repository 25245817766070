const PDFIcon = ({
    strokeWidth,
    ...props
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 56 56"
            fill="none"
            {...props}
        >
            <path
                fill="#DC0A20"
                d="M14.336 0h18.742l15.866 16.545v32.174c0 4.017-3.263 7.281-7.292 7.281H14.336a7.286 7.286 0 0 1-7.281-7.281V7.28A7.286 7.286 0 0 1 14.336 0Z"
            />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M33.055 0v16.416h15.887L33.055 0Z"
                clipRule="evenodd"
                opacity={0.302}
            />
            <path
                fill="#fff"
                d="M27.528 25c-.711.01-1.206.462-1.446.733-.557.626-.582 1.485-.396 2.317.186.832.585 1.718 1.071 2.615.055.1.115.169.172.27-.978 1.78-2.324 3.718-3.498 5.264-1.525.362-2.984.54-3.861 1.527-.734.827-.785 2.084 0 2.816.341.3.82.452 1.236.458a1.93 1.93 0 0 0 .993-.28c1.025-.625 1.692-1.954 2.431-3.49l5.992-1.396c.9 1.114 1.835 2.206 2.95 2.55.517.16 1.063.141 1.55-.035.489-.177.945-.526 1.153-1.064a2.05 2.05 0 0 0-.973-2.552c-.727-.392-1.633-.459-2.642-.415-.515.023-1.06.078-1.613.158-.653-.996-1.533-2.312-2.3-3.594.45-.893.84-1.773 1.01-2.58.187-.9.15-1.788-.361-2.472a1.72 1.72 0 0 0-1.468-.83Zm.492 1.544c.222.287.297.776.145 1.506-.084.405-.37.949-.577 1.443-.297-.61-.607-1.236-.714-1.713-.143-.64-.044-.995.12-1.236.115-.188.271-.304.495-.317.236-.013.393.14.53.317Zm-.37 5.52c.574.914 1.185 1.866 1.685 2.639l-4.01.973c.81-1.227 1.658-2.478 2.325-3.613Zm6.667 3.741c.44.238.573.615.413 1.038-.151.39-.878.495-1.198.38-.435-.135-1.136-.941-1.778-1.606.178-.044.393-.074.561-.082.645-.028 1.4-.013 2.002.27Zm-11.979 2.083c-.424.69-.902 1.627-1.181 1.797a.595.595 0 0 1-.757-.045c-.223-.216-.262-.703.091-1.1.36-.346.873-.49 1.847-.652Z"
            />
        </svg>
    );
}

export default PDFIcon;
