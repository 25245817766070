import { useEffect, useState } from 'react';
import { ArrowReturnRight, ArrowReturnLeft, XSquare } from "react-bootstrap-icons";
import './theme.css';
import {defaultEventList} from "../../utils";

const HistoryProgress = ({ events: eventsData }) => {

    const [events, setEvents] = useState(defaultEventList);

    useEffect(() => {
    }, [eventsData]);

    const EvenRow = ({ event, className, index }) => {
        return (
            <div className="d-flex event-progress-row-width">
                <div className='d-flex flex-column justify-content-center event-progress-element-width'>
                    {index > 0 ?
                        <hr className={`border-${className}`} />
                        : null}
                </div>

                <div className='d-flex flex-column justify-content-center  event-progress-element-width'>
                    <span className={`badge badge-pill bg-light border border-${className} event-progress-badge-width`}>
                        <img src={`./../icons/${event.split(' ').join('_')}.svg`} className={`text-${className}`} size={18} />
                    </span>
                </div>
            </div>
        )
    }
    return (
        <div className="d-flex">
            {
                events.map((event, index) => {
                    const { key, name, icon } = event;
                    return (eventsData[key])
                        ? <EvenRow event={name} icon={<></>} className="success" index={index} />
                        : <EvenRow event={name} icon={<></>} className="default" index={index} />
                })
            }
        </div >
    )

}

export default HistoryProgress;