import {useContext, useEffect, useState} from 'react';
import {GeneralContext} from '../../context/GeneralContext';
import {DataContext} from '../../context/DataContext';
import MapView from './../../components/Map/index';
import {useLocation} from 'react-router-dom';
import {defaultEventList, formatAreaCollection} from './../../utils';
import WellMarkerPopup from './../../components/Map/elements/well-popup'
import MapMarker from './../../components/Map/elements/marker'
import Acquisition from './../../components/Chart/acquisition';

import './theme.css';

const Journey = () => {

    const { state } = useLocation();

    const { axios } = useContext(GeneralContext);
    const { mapDefaultLatitude, mapDefaultLongitude, mapDefaultStyle } = useContext(DataContext);

    const [mapElements, setMapElements] = useState({ markers: [], areas: null });
    const [viewport, setViewport] = useState(
        {
            latitude: state ? state.latitude : mapDefaultLatitude,
            longitude: state ? state.longitude : mapDefaultLongitude,
            zoom: 11
        }
    );
    const [markerDetails, setMarkerDetails] = useState(null);
    const [wellEvents, setWellEvents] = useState(null);
    const [wellSatelliteImages, setWellSatelliteImages] = useState([]);
    const [fieldId, setFieldId] = useState(state ? state.fieldId : 'field');

    const getOilFieldsLocations = async () => {

        const { error: wellListingError, data: { data: wellData } } = await axios.get(`/well/transactions-by-key/${fieldId || "well"}?page=1&items=1000`);
        const uniqueWellData = wellData.reverse().reduce((wells, well) => {
            const { txid, keys, data: { json: { id, tokens } } } = well;
            if (wells[id] !== id && !wells[id]) {
                return { ...wells, [id]: { txid, ...well } }
            }
            return wells;
        }, {})

        const wellSatelliteImagesResult = await getWellSatelliteImages(fieldId);

        const { data: { data: wellDataLocation } } = await axios.get(`/location/transactions-by-key/${fieldId || 'well'}?page=1&items=1000`);

        const wellList = wellDataLocation.filter(({ keys }) => keys.includes('well')).map(({ data: { json: { id } } }) => id );

        const events = {};
        for (const well of wellList) {
            events[well] = await getWellEventsForWell(well);
        }

        const completedEventIndex = defaultEventList.filter((e) => e.type === "well").findIndex((e) => e.name ==="Tokens Issued");

        const markers = wellDataLocation
            .map(({ keys, data: { json: { lat, lon, id, area } } }) => {
                if (keys.includes('well')) {
                    const fid = id.split('-').pop();
                    const [ownerKey] = uniqueWellData[fid].keys.filter(key => key.startsWith('owner-'));
                    const txid = uniqueWellData[fid].txid;
                    const owner = ownerKey ? ownerKey.split('-').pop() : null;
                    return {
                        fieldId: fieldId,
                        area,
                        lat,
                        lon,
                        id,
                        type: 'marker',
                        icon: "well",
                        hasSatelliteImages: wellSatelliteImagesResult.find(well => well.id === id),
                        tokens: uniqueWellData[fid]?.tokens || 0,
                        className: `well ${events[id]?.length > 3 ? ((events[id]?.length >= completedEventIndex) ? 'well-status-active' : 'well-status-verified') : 'well-status-created'}`,
                        stream: "well",
                        owner,
                        txid
                    }
                } else {
                    return false;
                }
            })
            .filter(Boolean);

        const { data: { data: fieldData } } = await axios.get(`/location/transactions-by-key/${fieldId}?page=1&items=1000`);
        const fields = fieldData
            .map(({ keys, data: { json: { lat, lon, id, area } } }) => (keys.includes('truck') ? { area, lat, lon, id, type: 'marker', icon: "truck", className: `field ${uniqueWellData[id]}`, stream: "truck" } : false))
            .filter(Boolean);
        setMapElements({ markers, areas: { "type": "FeatureCollection", features: formatAreaCollection(fields) } })
    }

    useEffect(() => {

        const fetchOilFieldsLocationsData = async () => {
            await getOilFieldsLocations();
        }
        fetchOilFieldsLocationsData().catch(console.error);
    }, []);

    const rn = (max) => (Math.floor(Math.random() * max));

    const getWellSatelliteImages = async (fieldId) => {
        let wellSatelliteImages = {};
        try {
            const { error, data: { data: images } } = await axios.get(`/well-document-metadata/transactions-by-keys/?keys=${fieldId},image-satellite&page=1&items=1000`);
            wellSatelliteImages = images.map((image) => {
                const { data: { json } } = image;
                return json;
            }, {});
            setWellSatelliteImages(wellSatelliteImages);
            return wellSatelliteImages;
        } catch (error) {
            console.error(error);
            setWellSatelliteImages([]);
        }
        return [];
    }

    const getWellEvents = async (marker) => {

        if (marker) {
            const { id, owner } = marker;

            let formatteduserError = {};
            try {
                const { error: userError, data: { data: userEvents } } = await axios.get(`/event/transactions-by-key/${owner}?page=1&items=1000`);
                formatteduserError = userEvents.reduce((acc, eventData) => {
                    const { txid, timereceived, keys, data: { json: details } } = eventData;
                    const { name } = details;
                    return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
                }, {})

            } catch (error) {

            }

            let formattedFieldEvent = {};
            try {
                const { error: fieldError, data: { data: fieldEvent } } = await axios.get(`/event/transactions-by-key/${fieldId}?page=1&items=1000`);
                formattedFieldEvent = fieldEvent.reduce((acc, eventData) => {
                    const { txid, timereceived, keys, data: { json: details } } = eventData;
                    const { name } = details;
                    return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
                }, {})
            } catch (error) {

            }
            let formattedEventsData = {};
            try {
                const { error, data: { data: eventsData } } = await axios.get(`/event/transactions-by-key/${id}?page=1&items=1000`);
                let imageList = 0;
                formattedEventsData = eventsData.reduce((acc, eventData, index) => {
                    const { txid, timereceived, keys, data: { json: details } } = eventData;
                    const { name } = details;
                    if (acc[name]) {
                        imageList = imageList + 1;
                        return { ...acc, [`${name} ${imageList}`]: { txid, timereceived, data: { ...details } } };
                    } else {
                        return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
                    }
                }, {})
            } catch (error) {

            }

            setWellEvents({ ...formatteduserError, ...formattedFieldEvent, ...formattedEventsData, });
        }
    }

    const getWellEventsForWell = async (id) => {

        let formattedEventsData = [];
        try {
            const { error, data: { data: eventsData } } = await axios.get(`/event/transactions-by-key/${id}?page=1&items=1000`);
            formattedEventsData = eventsData.map((eventData) => {
                const { data: { json } } = eventData;
                return json;
            }, {})
        } catch (error) {
            console.error(error);
        }
        return formattedEventsData;
    }

    useEffect(() => {
    }, [mapElements, wellEvents, wellSatelliteImages])

    const acquisition = [
        {
            day: 'Jan',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Feb',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Mar',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Apr',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'May',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Jun',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Jul',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Aug',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Sep',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Oct',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Nov',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Dec',
            tokens: rn(90),
            wells: rn(30),
        },
    ];


    const MarkerList = () => {
        return mapElements.markers ?
            mapElements.markers.map((marker, index) => {
                return (
                    <div key={`markers-list-${index}`}>
                        <WellMarkerPopup
                            wellEvents={wellEvents}
                            wellSatelliteImages={wellSatelliteImages}
                            marker={marker}
                            markerDetails={markerDetails}
                            setMarkerDetails={setMarkerDetails}
                        />
                        <MapMarker
                            marker={marker}
                            setViewport={setViewport}
                            markerDetails={markerDetails}
                            setMarkerDetails={setMarkerDetails}
                            getWellEvents={getWellEvents}
                        />
                    </div>
                );
            }) : <></>;
    }

    return (
        <>
            <MapView
                viewport={viewport}
                setViewport={setViewport}
                mapElements={mapElements}
                mapType={mapDefaultStyle}
            >
                <MarkerList />
            </MapView>
            <div className="pabr text-center">
                <div className='row pt-2 pb-2 pl-4 pr-4'>
                    <div className='col-12 m-2'>
                        <Acquisition description={"1,000 Wells"} title="Wells In Production v. Wells Sealed 2023" data={acquisition} className="chart-overlay" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Journey;