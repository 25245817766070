import { useEffect } from 'react';
import moment from "moment";
import { ArrowReturnRight, ArrowReturnLeft } from "react-bootstrap-icons";
import ReactJsonViewCompare from 'react-json-view-compare';
import './theme.css';

const History = ({ items }) => {

    useEffect(() => {
    }, []);

    return (
        <div className="container py-2 mt-4 mb-4 timeline">
            {
                items.map((transaction, index) => {
                    const { txid, timereceived, data: { json } } = transaction;
                    const transactionNext = items[index + 1];
                    const jsonNext = transactionNext?.data?.json ? transactionNext.data.json: json;
                    return index % 2 === 0 ? (
                        <div className="row no-gutters">
                            <div className="col-sm py-2">
                                <div className="card border-success">
                                    <div className="card-body">
                                        <div className="float-right small"><strong>Registered in Blockchain at : </strong>{moment(timereceived * 1000).format('YYYY-MM-DD hh:mm:ss')}</div>
                                    </div>
                                    <div className="card-body">
                                        <ReactJsonViewCompare oldData={json} newData={jsonNext} />
                                    </div>
                                    <div className="card-body">
                                        <div className="text-center small"><strong>Tx ID : </strong>{txid}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                                <div className="row h-50">
                                    <div className="col border-right border-success">&nbsp;</div>
                                    <div className="col">&nbsp;</div>
                                </div>
                                <h5 className="m-2">
                                    <span className="badge badge-pill bg-light border border-success"><ArrowReturnLeft className="text-success" size={16} /></span>
                                </h5>
                                <div className="row h-50">
                                    <div className="col border-right border-success">&nbsp;</div>
                                    <div className="col"></div>
                                </div>
                            </div>
                            <div className="col-sm col-sm-timeline"> </div>
                        </div>
                    ) : (
                        <div className="row no-gutters">
                            <div className="col-sm col-sm-timeline"> </div>
                            <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                                <div className="row h-50">
                                    <div className="col border-right border-success">&nbsp;</div>
                                    <div className="col">&nbsp;</div>
                                </div>
                                <h5 className="m-2">
                                    <span className="badge badge-pill bg-light border border-success"><ArrowReturnRight className="text-success" size={16} /></span>
                                </h5>
                                <div className="row h-50">
                                    <div className="col border-right border-success">&nbsp;</div>
                                    <div className="col">&nbsp;</div>
                                </div>
                            </div>
                            <div className="col-sm py-2">
                                <div className="card border-success">
                                    <div className="card-body">
                                        <div className="float-right text-muted small"><strong>Registered in Blockchain at : </strong>{moment(timereceived * 1000).format('YYYY-MM-DD hh:mm:ss')}</div>
                                    </div>
                                    <div className="card-body">
                                       <ReactJsonViewCompare oldData={json} newData={jsonNext} />
                                    </div>
                                    <div className="card-body">
                                        <div className="text-center small"><strong>Tx ID : </strong>{txid}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )

                })
            }
        </div >
    )

}

export default History;