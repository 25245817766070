const metricCardClasses = {
  base: 'border border-muted bg-gray-0 p-4 dark:bg-gray-50 lg:p-6',
  rounded: {
    sm: 'rounded-sm',
    DEFAULT: 'rounded-lg',
    lg: 'rounded-xl',
    xl: 'rounded-2xl',
  },
};

const MetricCard = ({
  title,
  metric,
  icon,
  chart,
  info,
  rounded = 'DEFAULT',
  className,
  iconClassName,
  contentClassName,
  titleClassName,
  metricClassName,
  chartClassName,
  children,
}) => {
  return (
      <div
          className={`d-flex align-items-center ${metricCardClasses.base} ${metricCardClasses.rounded[rounded]} ${className}`}
      >
          <div className="d-flex flex-column align-items-stretch">
              <div className={`mb-0.5 text-gray-500 ${titleClassName} ${icon} ${contentClassName}`}>{title}</div>
              <div
              className={`font-lexend text-lg font-semibold text-gray-900 2xl:xl:text-xl dark:text-gray-700 ${metricClassName}`}
            > { metric }
              </div>
              {info ? info : null}

          </div>
          <div className="ml-auto h-12 w-20 w-auto h-auto text-center">
            {chart ? (
                <div className={`align-self-center h-12 w-20 ${chartClassName}`}>{chart}</div>
            ) : null}
          </div>
        {children}
      </div>
  );
}

export default MetricCard