import { useEffect } from 'react';
import moment from "moment";
import { ArrowReturnRight, ArrowReturnLeft, ExclamationCircle } from "react-bootstrap-icons";
import ReactJsonViewCompare from 'react-json-view-compare';
import './theme.css';

const Timeline = ({ items, stages }) => {

    useEffect(() => {
    }, []);

    return (
        <div className="container py-2 mt-4 mb-4 timeline">
            {
                stages.map((stage, index) => {
                    const stageTitle = stage.charAt(0).toUpperCase() + stage.slice(1).split('-').join(" ")
                    const transaction = items.find(item => (item.keys.includes(stage)));
                    const transactionNext = items.find(item => (item.keys.includes(stages[index + 1])));
                    if (transaction) {
                        const { txid, timereceived, data: { json } } = transaction;

                        let timereceivedNext = 0;
                        let jsonNext = {};

                        if (transactionNext) {
                            const { timereceived, data: { json } } = transactionNext;
                            timereceivedNext = timereceived;
                            jsonNext = json;
                        }

                        const hasBadTime = timereceived > timereceivedNext;
                        return index % 2 === 0 ? (
                            <div className="row no-gutters">
                                <div className="col-sm py-2" data-tooltip-id={`tooltip-${index}`}>
                                    <div className="card border-success">
                                        <div className="card-body">
                                            <div className={`float-right small ${hasBadTime ? 'text-warning' : 'text-success'}`}>{moment(timereceived * 1000).format('YYYY-MM-DD hh:mm:ss')}</div>
                                            <h4 className="card-title">{stageTitle}</h4>
                                            <ReactJsonViewCompare oldData={json} newData={jsonNext || json} />
                                        </div>
                                        <div className="card-body">
                                            <div className="text-center small">{txid}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                                    <div className="row h-50">
                                        <div className={`col border-right ${hasBadTime ? 'border-warning ' : 'border-success'}`}>&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                    <h5 className="m-2">
                                        <span className={`badge badge-pill bg-light border ${hasBadTime ? 'border-warning ' : 'border-success'}`}>
                                            <ArrowReturnLeft className={`${hasBadTime ? 'text-warning ' : 'text-success'}`} size={16} />
                                        </span>
                                    </h5>
                                    <div className="row h-50">
                                        <div className={`col border-right ${hasBadTime ? 'border-warning ' : 'border-success'}`}>&nbsp;</div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                                <div className="col-sm col-sm-timeline"> </div>
                            </div>
                        ) : (
                            <div className="row no-gutters">
                                <div className="col-sm col-sm-timeline"> </div>
                                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                                    <div className="row h-50">
                                        <div className={`col border-right ${hasBadTime ? 'border-warning ' : 'border-success'}`}>&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                    <h5 className="m-2">
                                        <span className={`badge badge-pill bg-light border ${hasBadTime ? 'border-warning ' : 'border-success'}`}>
                                            <ArrowReturnRight className={`${hasBadTime ? 'text-warning ' : 'text-success'}`} size={16} />
                                        </span>
                                    </h5>
                                    <div className="row h-50">
                                        <div className={`col border-right ${hasBadTime ? 'border-warning ' : 'border-success'}`}>&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                </div>
                                <div className="col-sm py-2">
                                    <div className="card border-success">
                                        <div className="card-body">
                                            <div className={`float-right small ${hasBadTime ? 'text-warning' : 'text-success'}`}>{moment(timereceived * 1000).format('YYYY-MM-DD hh:mm:ss')}</div>
                                            <h4 className="card-title">{stageTitle}</h4>
                                            <ReactJsonViewCompare oldData={json} newData={jsonNext || json} />
                                        </div>
                                        <div className="card-body">
                                            <div className="text-center small">{txid}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return index % 2 === 0 ? (
                            <div className="row no-gutters">
                                <div className="col-sm py-2">
                                    <div className="card border-danger">
                                        <div className="card-body">
                                            <div className="float-right small"></div>
                                            <h4 className="card-title">{stageTitle}</h4>
                                            <h4 className="card-title pb-0 mb-0 text-center small">Missing Stage Data</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                                    <div className="row h-50">
                                        <div className="col border-right border-danger">&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                    <h5 className="m-2">
                                        <span className="badge badge-pill bg-light border border-danger"><ExclamationCircle className="text-danger" size={16} /></span>
                                    </h5>
                                    <div className="row h-50">
                                        <div className="col border-right border-danger">&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                </div>
                                <div className="col-sm col-sm-timeline"> </div>
                            </div>
                        ) : (
                            <div className="row no-gutters">
                                <div className="col-sm col-sm-timeline"> </div>
                                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                                    <div className="row h-50">
                                        <div className="col border-right border-danger">&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                    <h5 className="m-2">
                                        <span className="badge badge-pill bg-light border border-danger"><ExclamationCircle className="text-danger" size={16} /></span>
                                    </h5>
                                    <div className="row h-50">
                                        <div className="col border-right border-danger">&nbsp;</div>
                                        <div className="col">&nbsp;</div>
                                    </div>
                                </div>
                                <div className="col-sm py-2">
                                    <div className="card border-danger">
                                        <div className="card-body">
                                            <h4 className="card-title">{stageTitle}</h4>
                                            <h4 className="card-title pb-0 mb-0 text-center small">Missing Stage Data</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div >
    )

}

export default Timeline;