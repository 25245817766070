import React, { useContext, useState, useEffect } from 'react';
import { Popup } from 'react-map-gl';
import { useNavigate } from "react-router-dom";
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Acquisition from './../../Chart/acquisition';
import { DataContext } from './../../../context/DataContext';
import { GeneralContext } from '../../../context/GeneralContext';
import QuickAccess from '../../Files/custom/quick-access';
import Forecast from './../../Chart/forecast';
import HistoryProgress from '../../HistoryProgress';

const tooltip = (
    <Tooltip id="tooltip">
        <div>This is a simplified calculation and assumes complete combustion of the oil, which might not be the case in real-world scenarios. The actual amount of CO₂ emitted can vary depending on various factors such as the combustion efficiency, impurities in the oil, and combustion conditions. Plus no count made for additional emissions caused by flaring or processing of oil in the extraction and  refinement processes.</div>
    </Tooltip>
);

const WellMarkerPopup = ({ marker, markerDetails, setMarkerDetails, wellEvents }) => {

    const { axios } = useContext(GeneralContext);
    const { setActiveRoute } = useContext(DataContext);

    const navigate = useNavigate();
    const [fileContentDetails, setFileContentDetails] = useState([]);
    const { lat, lon, id, icon, stream, tokens, hasSatelliteImages, fieldId, owner } = marker;

    const handleFileContent = async () => {
        try {
            const keys = `keys=${id},image-satellite`
            const { error, data: { data: documents } } = await axios.get(`/${stream}-document-metadata/transactions-by-keys?${keys}&page=1&items=10`);
            for await (const doc of documents) {
                const { data: { json: { name, hash, mimetype, extension } } } = doc;
                const { data: { error, data: content } } = await axios.get(`/document/${stream}/${id}/${name}`);
                if (!error) {
                    fileContentDetails.push({
                        extension,
                        data: extension === 'json' ? JSON.parse(Buffer.from(content, "base64").toString()) : `data:${mimetype};base64,${content}`
                    })
                    setFileContentDetails([...fileContentDetails]);
                }
            }
        } catch (err) {
            setFileContentDetails([]);
        }
    }

    const rnmin = (min, max) => (Math.random() * (max - min) + min);
    const rn = (max) => (Math.floor(Math.random() * max));


    const forecast = [
        {
            key: '2019',
            Target: rnmin(10, 50).toFixed(2),
            Actual: rnmin(50, 100).toFixed(2),
        },
        {
            key: '2020',
            Target: rnmin(10, 50).toFixed(2),
            Actual: rnmin(50, 100).toFixed(2),
        },
        {
            key: '2021',
            Target: rnmin(10, 50).toFixed(2),
            Actual: rnmin(50, 100).toFixed(2),
        },
        {
            key: '2022',
            Target: rnmin(10, 50).toFixed(2),
            Actual: rnmin(50, 100).toFixed(2),
        },
        {
            key: '2023',
            Target: rnmin(10, 50).toFixed(2),
            Actual: rnmin(50, 100).toFixed(2),
        }
    ];

    return (
        markerDetails && markerDetails.id === id) &&
        <Popup id={id} className='w-50' key={id} latitude={lat} longitude={lon} onClose={() => setMarkerDetails(null)} closeOnClick={false}  >
            <div className='row pl-2 pr-1'>
                <div className='col-4'>
                    <div className='row pr-2'>
                        <div className='col-12'>
                            <strong className='font-head'>Well Data </strong>
                            { markerDetails.txid ? <div className='text-left break-all small super-small-text'>{markerDetails.txid}</div> : <></>}
                            <hr />
                            <div className='d-flex flex-row'>
                                <div className='p-0'>Details : </div>
                                <Nav.Link className="text-info p-0 ms-1"
                                    onClick={() => {
                                        navigate(`/${icon}-data`, {
                                            state: {
                                                [`${icon}Id`]: id,
                                                fieldId,
                                                owner
                                            }
                                        });
                                        setActiveRoute(`${icon}`);
                                    }}>
                                    {id}
                                </Nav.Link>
                            </div>
                            <hr />
                            <strong>Quality </strong>
                            <div>API Gravity (degrees) : <span className="text-success">40.4</span></div>
                            <div>Sulphur content : <span className="text-success">0.79%</span></div>
                            <div>Carbon content : <span className="text-info">91.6%</span></div>
                            <hr />
                            <strong>Estimated Quantity </strong>
                            <div>Crude Oil : <span className="text-success">1,000,000 (Barrels) </span></div>
                            <div>Carbon : <span className="text-success">120,000 (MT)</span></div>
                            <OverlayTrigger placement="right" overlay={tooltip}>
                                <div>CO2 emissions avoided* : <span className="text-success">440,000 (MT)</span></div>
                            </OverlayTrigger>
                            <hr />
                            <div><strong>Tokens : </strong><span className="text-success">120,000</span></div>
                            <hr />
                            <div>
                                <strong>
                                    <Nav.Link className={` p-0 ms-1 ${hasSatelliteImages ? "text-info" : "text-default disabled"}`}
                                        disabled={!hasSatelliteImages}
                                        onClick={(e) => {
                                            if (hasSatelliteImages) {
                                                if (!!fileContentDetails.length) {
                                                    setFileContentDetails([]);
                                                } else {
                                                    handleFileContent()
                                                }
                                            }
                                        }}>
                                        {hasSatelliteImages ? `${fileContentDetails.length ? "Hide" : 'Show'} Satellite Images` : "Satellite Images Unavailable"}
                                    </Nav.Link>
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-8'>
                    <div className='row'>
                        {
                            fileContentDetails.length ? <div className='border-left-custom pl-2 pr-2 col-4'>
                                <div className='row mr-1 ml-1'>
                                    {
                                        fileContentDetails.map(({ data }, index) => {
                                            let bv = 0;
                                            let className = ''
                                            if (index) {
                                                bv = rnmin(0, 40).toFixed(0);
                                                className = bv < 10
                                                    ? ' border border-success'
                                                    : bv > 10 && bv < 30
                                                        ? ' border border-warning'
                                                        : ' border border-danger'
                                            }

                                            return index <= 1 ? (
                                                <div className={`col-12 ${className}`}>
                                                    {bv ? <div className='image-overlay text-center'><strong>Deviation</strong> : {bv / 100} %</div> : <></>}
                                                    <img className={`well-img-satellite p-1 ${className}`} alt="Render Images" width="100%" src={data} />
                                                    {!!index ? <></> : <hr />}
                                                </div>
                                            ) : <></>
                                        })
                                    }
                                </div>
                            </div>
                                : <></>
                        }
                        <div className={`border-left-custom pl-2 pr-2 ${fileContentDetails.length ? "col-8" : "col-12"}`}>
                            {
                                wellEvents ? (
                                    <div className='col-12 pb-3'>
                                        <HistoryProgress events={wellEvents} />
                                    </div>
                                ) : <></>
                            }
                            <Forecast labels={[{ name: "Actual", color: "#00D1FF" }, { name: "Target", color: "#6B46FF" }]} description={"$"} title="Sealed Well: Carbon Value" data={forecast} className="chart-fluid" key1="Target" key2="Actual" />
                        </div>
                    </div>
                </div>
            </div>
        </Popup >



}

export default WellMarkerPopup