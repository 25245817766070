import React, { useContext, useState, useEffect } from 'react';
import { Popup } from 'react-map-gl';
import { useNavigate } from "react-router-dom";
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DataContext } from './../../../context/DataContext';
import { GeneralContext } from './../../../context/GeneralContext';
import moment from 'moment';
import { Card, Button } from 'react-bootstrap';
import MapView from './../index';
import VesselPopup from './vessel-popup';
import MapMarker from './marker';

const tooltip = (
    <Tooltip id="tooltip">
        <div>This is a simplified calculation and assumes complete combustion, which might not be the case in real-world scenarios. The actual amount of CO₂ emitted can vary depending on various factors such as the combustion efficiency, impurities in the oil, and combustion conditions.</div>
    </Tooltip>
);

    const renderData = (data) => {
        // List of keys that should be rendered in uppercase
        const uppercaseKeys = [
            'Id',
            'Rfid'
        ];
        return Object.keys(data).map(key => {

            const value = data[key];
            let formattedKey = key.split('_').join(" ");
            formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
            formattedKey = uppercaseKeys.includes(formattedKey) ? formattedKey.toUpperCase() : formattedKey;

            if (Array.isArray(value)) {
                // If the value is a flat array, join its elements with commas
                return (
                    <li key={key}>
                        <strong className='ps-2'>
                            {formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1)}
                        </strong>: {
                            ['date', 'timestamp'].includes(key)
                                ? moment(data[key]).format('YYYY-MM-DD hh:mm:ss')
                                : ['coordinates'].includes(key)
                                    ? data[key].map((c, i) => {
                                        return <li><strong className='ps-2'>{!!i ? 'Longitude' : 'Latitude'} :</strong> {c}</li>
                                    })
                                    : (value[0] && typeof value[0] === 'object') ? <ul>{renderData(value[0])}</ul> : value.join(', ')
                        }
                    </li>
                );
            } else if (typeof value === 'object' && value !== null) {
                // If the value is an object (including nested objects), recursively render its contents
                return (
                    <li key={key}>
                        <strong className='ps-2'>
                            {formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1)}
                        </strong>:
                        <ul>
                            {renderData(value)}
                        </ul>
                    </li>
                );
            } else {
                // If the value is neither an array nor an object, render it as usual
                return (
                    <li key={key}>
                        <strong className='ps-2'>
                            {formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1)}
                        </strong> : {value}
                    </li>
                );
            }
        });
    }

const HaulierPopup = ({ marker, markerDetails, setMarkerDetails }) => {

    const navigate = useNavigate();

    const { setActiveRoute } = useContext(DataContext);
    const { axios } = useContext(GeneralContext);

    const [fileContentDetails, setFileContentDetails] = useState([]);
    const { lat, lon, id, txid, payload: { name, ...details } } = marker;
    return (markerDetails && markerDetails.id === id) &&
        <Popup className='geo-popup geo-popup-width p-0' id={id} key={`vessel-${id}`} latitude={lat} longitude={lon} onClose={() => setMarkerDetails(null)} closeOnClick={false}  >
            <Card className="w-100">
                <Card.Title className="text-center pt-1 m-1">
                    {name}
                </Card.Title>
                <Card.Body className="p-0">
                    <div className="m-1 p-1 border border-default">
                        <MapView
                            viewport={{ latitude: lat, longitude: lon, zoom: 16 }}
                            setViewport={{ latitude: lat, longitude: lon, zoom: 16 }}
                            mapElements={{ markers: [marker] }}
                            mapType="mapbox://styles/mapbox/satellite-streets-v12"
                            renderActions={false}
                        >
                            <MapMarker marker={marker} markerDetails={markerDetails} setMarkerDetails={() => { }} />
                        </MapView >
                    </div>
                    <Card.Text>
                        <ul>
                            <li><strong>TX ID</strong> : {txid}</li>
                            {renderData(details)}
                        </ul>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Popup>
}

export default HaulierPopup