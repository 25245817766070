import { Nav, Navbar } from 'react-bootstrap';
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { DataContext } from './../../context/DataContext';

const Navigation = ({ routes }) => {
    const { user, role, activeRoute, setActiveRoute, handleLogout, totalTokens, systemUsers, userAssets } = useContext(DataContext);
    const navigate = useNavigate();
    const AuthenticationRoutes = () => {
        return !user
            ? <Nav>
                <Nav.Link
                    eventKey={2}
                    href="#"
                    key='sign-in'
                    onClick={() => {
                        navigate("/", { state: {} });
                        setActiveRoute('/')
                    }}
                    className={`nav-item ${activeRoute === '/login' ? 'active' : ''}`}
                >
                    Sign in
                </Nav.Link>
            </Nav>
            : <div className="d-flex justify-content-center align-items-center">
                <Nav>
                    <Nav.Link
                        eventKey={2}
                        href="#"
                        key='sign-out'
                        onClick={async () => {
                            navigate("/login", { state: {} });
                            await handleLogout();
                            setActiveRoute('/login')
                        }}
                        role="button"
                        className={`nav-item ${activeRoute === '/login' ? 'active' : ''}`}
                    >

                        <span className="ml-2">Sign Out</span>
                    </Nav.Link>
                </Nav>
            </div>
    }

    const CustomRoutes = ({ navigation }) => {
        return user
            ? <Nav className="mr-auto">
                {
                    ['admin', 'user'].includes(role) &&
                    <Nav.Link
                        href="#"
                        key="navigation-field"
                        onClick={() => {
                            navigate("/journeys-data", { state: {} });
                            setActiveRoute('field')
                        }}
                        className={`nav-item ${activeRoute === 'field' ? 'active' : ''}`}
                    >
                        Journeys
                    </Nav.Link>
                }
            </Nav>
            : <Nav className="mr-auto"></Nav>

    }

    return (
        <>
            <Navbar.Brand
                onClick={() => {
                    navigate("/", { state: {} });
                    setActiveRoute('/')
                }}>
                <img alt="Logo" src="logo.png" height={30} />
            </Navbar.Brand>
            <CustomRoutes />
            <AuthenticationRoutes />
        </>
    )

}

export default Navigation;