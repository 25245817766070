import { Modal, Button, Nav } from 'react-bootstrap';
import { PiCaretLeftBold, PiCaretRightBold } from 'react-icons/pi';
import { useScrollableSlider } from '../../Shared/use-scrollable-slider';

import { XSquare } from "react-bootstrap-icons";
import { GeneralContext } from '../../../context/GeneralContext';

import DocIcon from './../../Shared/icons/doc-solid';
import ImageIcon from './../../Shared/icons/image-solid';
import XMLIcon from './../../Shared/icons/xml-solid';
import PDFIcon from './../../Shared/icons/pdf-solid';

import { useEffect, useState, useContext } from 'react';

import './theme.css';
import {handleFileRender, handleIcons} from "../../../utils";

const QuickAccess = ({ stream, withIcons, className, classNameIcons, title, id, files, viewAll }) => {
    const {
        sliderEl,
        sliderPrevBtn,
        sliderNextBtn,
        scrollToTheRight,
        scrollToTheLeft,
    } = useScrollableSlider();


    const { axios } = useContext(GeneralContext);

    const [filesList, setFilesList] = useState(files);
    const [fileContentDetails, setFileContentDetails] = useState({ extension: '', data: '' });
    const [error, setError] = useState(false);

    const handleFileContent = async (stream, extension, mimetype, id, filename, txid) => {
        try {
            const { data: { error, data: content } } = await axios.get(`/document/${stream}/${id}/${filename}`);
            if (!error) {
                setFileContentDetails({
                    txId: txid,
                    filename: filename,
                    extension,
                    data: extension === 'json' ? JSON.parse(Buffer.from(content, "base64").toString()) : `data:${mimetype};base64,${content}`
                });
            }
        } catch (err) {
            setFileContentDetails({ extension: '', data: '' });
            setError(true);
        }
    }

    useEffect(() => {
        setFilesList(prev => (files.reduce((acc, file) => {
            const { txid, data: { json } } = file;
            const { extension, size, name, hash, filename } = json;
            acc.push({ txid: txid, payload: json, id: hash, file: { name, image: handleIcons(extension) } });
            return acc;
        }, [])));
    }, [])


    useEffect(() => { }, [filesList])

    return (
        <>
            <Modal
                className={`${className} max-w-full rounded-md p-6`}
                size="lg"
                show={!!fileContentDetails.data}
                onHide={() => setFileContentDetails({ extension: '', data: '' })}
            >
                <Modal.Header
                    className="flex items-center justify-between"
                >
                    <Modal.Title>
                        <h6 className='mt-3'>File: {fileContentDetails.filename}</h6>
                        {fileContentDetails.txId && <small className={'text-xs'}>{fileContentDetails.txId}</small>}
                    </Modal.Title>
                    <XSquare className='m-3' onClick={() => setFileContentDetails({ extension: '', data: '' })} size={17} />

                </Modal.Header>
                <Modal.Body className="h-100">
                    {handleFileRender(fileContentDetails)}
                    {error && <div className="text-danger">Unable to load file</div>}
                </Modal.Body>
                <Modal.Footer>
                    <div onClick={() => setFileContentDetails({ extension: '', data: '' })} className="btn btn-info">Close</div>
                </Modal.Footer>
            </Modal>
            {
                filesList.length ? (
                    <div className={`${className}`} >
                        <div className="col-span-full mb-3 flex items-center justify-between 2xl:mb-5">
                            {
                                title && <div as="h3" className="text-lg font-semibold xl:text-xl">
                                    {title}
                                </div>
                            }
                            {viewAll ? (
                                <Nav.Link
                                    href={'# '}
                                    className="text-sm font-medium text-gray-900 hover:underline"
                                >
                                    View all
                                </Nav.Link>)
                                : <></>
                            }
                        </div>
                        <div className="relative">
                            <Button
                                title="Prev"
                                variant="text"
                                ref={sliderPrevBtn}
                                onClick={() => scrollToTheLeft()}
                                className="max-z !absolute left-0 top-0 z-10 !h-full w-8 !justify-start rounded-none bg-gradient-to-r from-white via-white to-transparent px-0 text-gray-500 hover:text-black dark:from-gray-50/80 dark:via-gray-50/80 3xl:hidden"
                            >
                                <PiCaretLeftBold className="h-5 w-5" />
                            </Button>
                            <div className="w-full overflow-hidden">
                                <div
                                    ref={sliderEl}
                                    className="custom-scrollbar-x grid grid-flow-col gap-5 overflow-x-auto scroll-smooth"
                                >
                                    {
                                        filesList.length ? filesList.map((item) => {

                                            return (
                                                <div
                                                    className={`${className} relative flex flex-col items-center justify-center rounded-lg bg-gray-50 p-7 dark:bg-gray-100/50`}
                                                >
                                                    {withIcons && item?.file?.image && (
                                                        <div className="w-14">
                                                            <item.file.image />
                                                        </div>
                                                    )}
                                                    <Nav.Link
                                                        key={'hash'}
                                                        className="text-info p-0 ms-1"
                                                        onClick={async (e) => {
                                                            const { txid, payload: { extension, mimetype, hash } } = item;
                                                            await handleFileContent(stream, extension, mimetype, id, `${item?.file?.name}`, txid)
                                                        }}>
                                                        {item?.file?.name}
                                                    </Nav.Link>
                                                </div>
                                            );
                                        }) : <></>}
                                </div>
                            </div>
                            <Button
                                title="Next"
                                variant="text"
                                ref={sliderNextBtn}
                                onClick={() => scrollToTheRight()}
                                className="max-z !absolute right-0 top-0 z-10 !h-full w-8 !justify-end rounded-none bg-gradient-to-l from-white via-white to-transparent px-0 text-gray-500 hover:text-black dark:from-gray-50/80 dark:via-gray-50/80 3xl:hidden"
                            >
                                <PiCaretRightBold className="h-5 w-5" />
                            </Button>
                        </div>
                    </div>
                )
                    : <></>

            }
        </>
    )

}

export default QuickAccess;