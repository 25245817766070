import React, { useContext, useState } from 'react';
import Map, { Layer, Source, ScaleControl, NavigationControl, FullscreenControl } from 'react-map-gl';
import { GeneralContext } from '../../context/GeneralContext';

import './mapgl.css';
import './theme.css';

const MapView = ({ children, mapElements: { areas, routes }, mapType, viewport, setViewport, renderActions = true }) => {

    const { env } = useContext(GeneralContext);
    const [areaConfiguration,] = useState(
        {
            id: 'truck',
            type: 'fill',
            paint: { 'fill-color': "#2da0ff", 'fill-opacity': 0.3 }
        }
    );
    const [routesConfigurationComplete,] = useState(
        {
            id: 'complete',
            type: 'line',
            layout: { "line-join": "round", "line-cap": "round" },
            paint: { 'line-color': "#3d8f01", 'line-width': 5 }
        }
    );
    const [routesConfigurationIncomplete,] = useState(
        {
            id: 'incomplete',
            type: 'line',
            layout: { "line-join": "round", "line-cap": "round" },
            paint: { 'line-color': "#fd4141", 'line-width': 3 }
        }
    );

    const [areaLimitConfiguration,] = useState(
        {
            id: 'route',
            type: 'line',
            layout: { "line-join": "round", "line-cap": "round" },
            paint: { 'line-color': "#660101", 'line-width': 2 }
        }
    );


    return (
        <>
            <Map
                initialViewState={viewport}
                pitch={60}
                maxZoom={16}
                minZoom={5}
                scrollZoom={true}
                mapboxAccessToken={env.maps}
                mapStyle={mapType}
            >
                {children}
                {
                    renderActions && (
                        <>
                            <ScaleControl />
                            <NavigationControl />
                            <FullscreenControl />

                        </>
                    )
                }
                {
                    areas ? <Source type="geojson" data={areas}>
                        <Layer {...areaConfiguration} />
                    </Source> : <></>
                }
                {
                    routes && routes.complete ? <Source type="geojson" data={routes.complete}>
                        <Layer  {...routesConfigurationComplete} />
                    </Source> : <></>
                }
                {
                    routes && routes.incomplete ? <Source type="geojson" data={routes.incomplete}>
                        <Layer  {...routesConfigurationIncomplete} />
                    </Source> : <></>
                }

            </Map>
        </>
    );
}

export default MapView