import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Core from './components/Core/index';
import axios, { env, GeneralContext } from './context/GeneralContext';
import './assets/c1.css';
import './assets/bootstrap.css';
import './assets/custom.css';

ReactDOM.render(
  <GeneralContext.Provider
    value={{ env, axios }}>
    <Core />
  </GeneralContext.Provider>,
  document.getElementById('root')
);

reportWebVitals();
